@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Mulish:wght@300;700');

.vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .auth-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: left;
  }
  
  .auth-inner {
    width: 450px;
    margin: auto;
    background: #ffffff;
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
    padding: 40px 55px 45px 55px;
    border-radius: 15px;
    transition: all .3s;
  }

  * {
    box-sizing: border-box;
  }
  body {
    min-height: 100vh;
    display: flex;
    font-weight: 300;
    font-family: 'Mulish', sans-serif;
  }
  
  h1,h2,h3,h4,h5,h6 {
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
    padding-top: 10px;
    padding-bottom: 5px;
  }
  
  body, html, .App, #root, .auth-wrapper {
    width: 100%;
    height: 100%;
  }

  .btn {
    border-radius: 200px;
  }

  .btn-primary {
    background: #f4710c;
    border-color: #f4710c;
  }

  .btn-primary:hover, .btn-primary:active {
	background: #f5832b !important;
	border-color: #f5832b !important;
  }

  .btn-primary:focus {
	background: #f5832b !important;
	border-color: #f5832b !important;
	box-shadow: 0 0 0 0.2rem #f4710c;
  }

  b {
    font-weight: 700;
  }